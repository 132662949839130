import { GetStaticProps, GetStaticPropsContext } from "next";
import { useTranslation } from "next-i18next";
import { Button, useAuthStatus } from "@pairtreefamily/ui";
import Link from "next/link";
import { useGetAuthUserData } from "@api";
import { UnauthorizedMessage } from "@shared/components";
import { loadTranslations } from "@shared/server";
import { Routes } from "@features/routing";
import { Box } from "@mui/material";

const IndexAuthWrapper = () => {
  const { t } = useTranslation("common");

  const { authedUser } = useAuthStatus();
  const { data: userData } = useGetAuthUserData();

  if (!authedUser?.email || !userData || !userData.ok) {
    return <UnauthorizedMessage />;
  }

  const { cases } = userData.content;
  const firstCase = cases[0];

  if (!firstCase) {
    return <p>{t("root.messages.emptyCases")}</p>;
  }

  return <AdminPage />;
};

function AdminPage() {
  const { t } = useTranslation("common");

  return (
    <Box
      mt={2}
      ml={2}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
    >
      <p>{t("root.caption")}</p>

      <Box display="flex" gap={1} mt={3}>
        <Link href={Routes.Admin}>
          <Button onClick={() => {}}>{t("root.navigation.admin")}</Button>
        </Link>
        <Link href={Routes.Family}>
          <Button onClick={() => {}}>{t("root.navigation.family")}</Button>
        </Link>
        <Link href={Routes.ProfessionalDashboard}>
          <Button onClick={() => {}}>
            {t("root.navigation.professional")}
          </Button>
        </Link>
      </Box>
    </Box>
  );
}

export const getStaticProps: GetStaticProps = async ({
  locale,
}: GetStaticPropsContext) => loadTranslations(["common", "components"], locale);

export default IndexAuthWrapper;
